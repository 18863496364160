<template>
	<div v-if="!isMobile" class="sidebar-detached sidebar-left">
		<div class="sidebar bg-white">
			<div class="sidebar-shop" :class="{ 'show': mqShallShowLeftSidebar }">				
				<b-card>
					<div class="multi-range-price">
						<h6 class="filter-title mt-0">Precios Lista</h6>
						<b-form-radio-group v-model="filters.priceRangeDefined" class="price-range-defined-radio-group"
							stacked :options="options" />
					</div>					
				</b-card>
			</div>
		</div>

		<div class="body-content-overlay" :class="{ 'show': mqShallShowLeftSidebar }"
			@click="$emit('update:mq-shall-show-left-sidebar', false)" />
	</div>
	<div v-else class="sidebar-detached sidebar-left">
		<div class="sidebar bg-white">
			<b-sidebar id="sidebar-1" no-header shadow bg-variant="white" backdrop :visible="mqShallShowLeftSidebar"
				@change="(val) => $emit('update:mq-shall-show-left-sidebar', val)">

				<template #default="{ hide }">
					<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
						<h5 class="mb-0">Filtros</h5>
						<feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
					</div>

					<div class="my-2 ml-2 multi-range-price">
						<h6 class="filter-title mt-0">Precios Lista</h6>
						<b-form-radio-group v-model="filters.priceRangeDefined" class="price-range-defined-radio-group"
							stacked :options="options" />
					</div>

				</template>
			</b-sidebar>
		</div>
	</div>
</template>

<script>
import {
	BRow, BCol, BCard, BFormRadioGroup, BLink, BSidebar, VBToggle
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import store from '@/store';
import PricesArticlesProvider from '@/providers/PricesArticles';
const PricesArticlesResource = new PricesArticlesProvider();

export default {
	components: {
		BRow,
		BCol,
		BFormRadioGroup,
		BLink,
		BCard,
		BSidebar,

		// 3rd Party
		VueSlider,
	},
	directives: {
		'b-toggle': VBToggle,
	},
	props: {
		filters: {
			type: Object,
			required: true,
		},
		filterOptions: {
			type: Object,
			required: true,
		},
		mqShallShowLeftSidebar: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			options: [],
			isMobile: false,
		}
	},
	beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
	async mounted() {
		window.addEventListener('resize', this.handleResize);
        this.handleResize()

		await this.getAllPricesList()
	},
	methods: {
		handleResize() {
            this.isMobile = window.innerWidth < 998;
        },
		async getAllPricesList() {
			this.options = []
			try {
				const companyId = store.getters['auth/getConexionId']
				const { data } = await PricesArticlesResource.getAllPricesList(companyId)
				this.options = data.data.map(priceList => {
					return {
						text: priceList.nombre,
						value: priceList.precio_Empresa_Id,
					}
				})
			} catch (e) {
				console.log(e)
				this.handleResponseErrors(e)
			}
		}
	},
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
	::v-deep>.custom-control {
		margin-bottom: 0.75rem;
	}
}

.ecommerce-application .sidebar-shop {
	width: 260px;
	z-index: 1500000 !important;
}
</style>
